<template>
  <vx-card no-shadow>
    <vs-list>
      <vs-list-header title="Credit" icon="monetization_on"></vs-list-header>
      <p class="text-danger">ยอดเงิน : <a class="text-success" >{{currency(memberData.member_balance)}} บาท</a> </p>
       <p class="text-danger">ยอดเงินในเกม : <a class="text-success" >{{currency(memberData.igBalance)}} บาท</a> </p>
      <p class="text-danger">
        ยอดลิมิตถอน : {{ currency(memberData.member_limitwithdraw) }} บาท
      </p>
      <p v-if="memberData.member_level == 3" class="text-danger">
        สถานะลิมิตถอน : ติดสถานะลิมิตถอน
      </p>
       <p v-else class="text-success">
        สถานะลิมิตถอน : ไม่ติดสถานะอั้นถอน
      </p>
      <!-- <p v-if="memberData.member_play_fish == false" class="text-danger">สถานะยิงปลา : <a class="text-success" >ไม่ได้อยู่ในเกม</a>  </p>
      <p v-if="memberData.member_play_fish == true" class="text-success">สถานะยิงปลา : <a class="text-success" >อยู่ในเกมยิงปลา</a>  </p>
      <p v-if="memberData.member_play_lotto == false" class="text-danger">สถานะหวย : <a class="text-success" >ไม่อยู่ในเกม</a>  </p>
      <p v-if="memberData.member_play_lotto == true" class="text-success">สถานะหวย : <a class="text-success" >อยู่ในเกม</a>  </p> -->
      <p v-if="memberData.member_kyc == false" class="text-danger">สถานะ KYC : <a class="text-danger" >ยังไม่ยืนยัน</a>  </p>
      <p v-if="memberData.member_kyc == true" class="text-danger">สถานะ KYC : <a class="text-success" >ยืนยันแล้ว</a>  </p>
      <p v-if="memberData.member_bonus == 0" class="text-success">
        โบนัสล่าสุดที่ลูกค้ารับ : {{statusBonus[0][memberData.member_bonus]}}
      </p>
      <p v-else class="text-danger">
        โบนัสล่าสุดที่ลูกค้ารับ : {{statusBonus[0][memberData.member_bonus]}}
      </p>
      <p v-if="memberData.member_bonus_type == 0" class="text-success">ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success" >ไม่มี</a> </p>
      <p v-else-if="memberData.member_bonus_type == 1" class="text-danger">ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success" >CASINO</a>
      </p>
      <p v-else-if="memberData.member_bonus_type == 2" class="text-danger">ประเภทโบนัสล่าสุดที่ลูกค้ารับ :
      <a class="text-success" >SLOT</a>  </p>
      <p v-else-if="memberData.member_bonus_type == 3" class="text-danger">ประเภทโบนัสล่าสุดที่ลูกค้ารับ :  <a class="text-success" >SPORT</a> </p>
      <p v-if="title_name=='SLOTHENG'" class="text-danger">ย้ายข้อมูลมาจาก  : <a class="text-success" >{{memberData.member_trans_from}}</a>  </p>
      <p>
        หมายเหตุ : {{ commentData[0].comment }}
      </p>
      <vs-divider />
      <p>ระดับ Ranking : VIP {{memberData.ranking_level}} </p>
      <p>ยอดเทิร์นโอเวอร์ Ranking : {{currency(memberData.member_sum_turnranking)}}</p>
      <vs-divider />
      <p>ยอดเทิร์นโอเวอร์ที่ลูกค้าทำปัจจุบัน : {{currency(memberData.member_sum_turnover)}}</p>
      <p>ยอดเทิร์นโอเวอร์ขั้นต่ำที่ต้องทำ : {{currency(memberData.member_turnover)}}</p>
      <p>ยอดคืนยอดเล่น : {{currency(memberData.member_turn_point)}}</p>
      <p>ยอดแนะนำเพื่อน : {{currency(memberData.member_aff_point)}}</p>
      <!-- BEGIN OLD_USERNAME -->
      <vs-divider />
        <h3>ดึงข้อมูลยูสเก่าจาก UFA</h3>
        <vs-input class="mr-20 mt-5" placeholder="username เก่าของลูกค้า" v-model="oldUsername"/>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20 mt-5" size="small" icon-pack="feather" icon="icon-edit" color="danger"
          @click="getOldInfoFromUFA()">ปุ่มดึงข้อมูลยูสเก่าจาก UFA</vs-button>
        <p class="mt-4">username ปัจจุบันของลูกค้าจะเปลี่ยนไปเป็น username ที่ท่านกรอกด้านบน</p>
        <p class="mt-4">โปรดยืนยันตัวตนข้อมูลค้าก่อนดึงข้อมูล การดึงข้อมูลลูกค้าในส่วนนี้จะถูกเก็บประวัติทั้งหมด</p>
      <vs-divider />
      <!-- END OLD_USERNAME -->
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20 mt-10" size="small" icon-pack="feather" icon="icon-edit" color="danger"
        @click="registerUFABET()">ปุ่มสมัครยูส UFABET</vs-button>
        <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small" icon-pack="feather" icon="icon-edit" color="success"
        @click="syncline()"> ยกเลิกเชื่อมต่อไลน์</vs-button>
        <br>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small" icon-pack="feather" icon="icon-edit" color="warning"
        @click="popupkyc()"> ยืนยัน KYC</vs-button>
        <br>
      <vs-divider />


      <vs-popup classContent="popup-example" title="ยืนยัน KYC" :active.sync="popup_kyc">
        <br>
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <p> กดยืนยัน KYC </p>
          </template>
        </vx-input-group>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" @click="confrimKyc()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <vs-list-header title="ข้อมูลสำหรับเข้าเล่น UFA" color="info" icon="people"></vs-list-header>
      <p v-if="memberData.member_username">ยูสเซอร์เนม: {{'ufnblej' + memberData.member_username.toLowerCase()}}</p>
      <vs-divider />
      <p>รหัสผ่าน: {{memberData.member_password}}</p>
      <vs-divider />

      <vs-list-header title="ข้อมูลสมาชิก" color="success" icon="description"></vs-list-header>
      <br />
      <p>ยูสเซอร์เนม : {{memberData.member_username}}</p>
      <vs-divider />
      <p>เบอร์โทร : {{memberData.member_phone}}</p>
      <vs-divider />
      <p>รหัสผ่าน : {{memberData.member_password}}</p>
      <vs-divider />
      <p>ไอพี : {{memberData.member_lastplay_ip}} </p>
      <vs-divider />
      <p>ชื่อ - นามสกุล : {{memberData.member_name}} {{memberData.member_surname}}</p>
      <vs-divider />
      <p>เลขบัญชี : {{memberData.member_bank_number}}</p>
      <vs-divider />
      <p>ธนาคาร : {{memberData.member_bank_type}}</p>
      <vs-divider />
      <p>ไลน์ : {{memberData.member_line}}</p>
      <vs-divider />
      <p>วันที่สมัคร : {{ moment(memberData.member_register_date).format('YYYY-MM-DD HH:mm:ss')}}</p>
      <vs-divider />
      <p>วันเกิด: {{ memberData.member_birthdate ? moment(memberData.member_birthdate).format("YYYY-MM-DD") : '-' }}</p>
      <vs-divider />
      <p class="text-success">สถานะการเชื่อมต่อ LINE : <span v-if="memberData.member_line_user_id !== null" >เชื่อมต่อแล้ว</span><span v-else class="text-warning">ไม่ได้เชื่อมต่อ</span></p>
      <vs-divider />
      <p class="text-primary">ผู้ที่แนะนำมา ขั้นที่ 1 : {{memberData.member_aff}}</p>
      <vs-divider />
      <p class="text-success">ผู้ที่แนะนำมา ขั้นที่ 2 : {{memberData.member_aff2}}</p>
      <vs-divider />
      <p class="text-success">ระดับบัญชีลูกค้า : {{max_deposit}}</p>
      <vs-divider />
      <p class="text-warning">บัญชีที่ลูกค้าเห็น (scb/kbank) : {{memberDatabank.bank_for}}
        {{memberDatabank.bank_fullname}} {{memberDatabank.bank_number}} {{memberDatabank.bank_reserve}}</p>
      <p class="text-warning">บัญชีที่ลูกค้าเห็น (bay) : {{memberDatabankbay.bank_for}}
        {{memberDatabankbay.bank_fullname}} {{memberDatabankbay.bank_number}} {{memberDatabankbay.bank_reserve}}</p>
      <br />
      <vs-list-header title="สถิติ" color="blue" icon="swap_horiz"></vs-list-header>
      <p>ยอดฝาก : {{currency(memberData.member_topup)}} บาท</p>
      <p>ยอดถอน : {{currency(memberData.member_withdraw)}} บาท</p>
      <br />
    </vs-list>
  </vx-card>
</template>

<script>
import axios from '../../../axios'
export default {
  data () {
    return {
      turnover: 0,
      bank_see: '',
      sum_deposit: 0,
      sum_withdraw: 0,
      max_deposit:'',
      memberData: {},
      memberDatabank: {},
      memberDatabankbay: {},
      memberDataSumtransaction: [],
      balance:'',
      commentData: [],
      statusBonus: [
        {
          0: 'ไม่รับโบนัส',
          1: '-',
          2: '-',
          3: 'เปิดไพ่ลุ้นโชค',
          4: 'กงล้อลุ้นโชค',
          5: 'โบนัสเครดิตฟรี',
          6: 'โบนัสเครดิตฟรี Ranking',
          7: 'Happy Time',
          8: 'ฝาก 200 รับ 500',
          9: 'ฝาก 500 ได้ 1500',
          10: 'โปรโมชั่นสมาชิกใหม่ 100%',
          11: 'โปรโมชันฝากครั้งแรกของวัน รับ 30%',
          12: 'โปรโมชัน ฝาก 500 รับ 600 บาท'
        }
      ],
      popup_kyc: false,
      popup_playfish: false,
      popup_playlotto: false,
      title_name:process.env.VUE_APP_TITLE,
      // ==== GET_OLD =====
      popupGetOld: false,
      oldUsername: ''
    }
  },
  methods: {
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    async confrimKyc () {
      await axios
        .post('member/confrimkyc', {
          username: this.$route.params.username
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ยืนยัน KYC ไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    },
    async playfish () {
      await axios
        .post('member/playfish', {
          username: this.$route.params.username
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขสถานะยิงปลาไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    },
    async updateaff () {
      await axios
        .post('member/updateaff', {
          username: this.memberData.member_username,
          phone: this.memberData.member_phone
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'อัพเดทรายชื่อแนะนำเพื่อนไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    },
    async playlotto () {
      await axios
        .post('member/lotto', {
          username: this.$route.params.username
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขสถานะหวยไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    },
    async syncline () {
      await axios
        .post('member/syncline', {
          username: this.$route.params.username
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          // title: 'แก้ไขสถานะยิงปลาไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
    },
    async editWinLose () {
      await axios
        .get(`member/${  this.$route.params.username}/correctWinLose`, {
        }).then(response => (this.status = response.data))
      if (this.status.success === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ทำรายการสำเร็จ',
          text: this.status.message
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: this.status.message
        })
      }
    },
    async registerUFABET () {
      await axios
        .get(`member/${this.$route.params.username}/registerUFABET`, {
          params: {
            password: this.memberData.member_password
          }
        }).then(response => (this.status = response.data))
      if (this.status.success === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ทำรายการสำเร็จ',
          text: this.status.message
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: this.status.message
        })
      }
    },
    async popupkyc () {
      this.popup_kyc = true
    },
    async popupplayfish () {
      this.popup_playfish = true
    },
    async popupplaylotto () {
      this.popup_playlotto = true
    },
    // ============== GET_OLD ======================
    async clearGetOldData () {
      this.oldUsername = ''
    },
    async getOldInfoFromUFA () {
      try {
        const data = {
          'oldUserName': this.oldUsername,
          'newUserName': this.$route.params.username
        }

        const request =  await axios
          .post('/member/change/username', data)

        const result = request.data

        if (result.status === true) {
          this.$vs.notify({
            time: 4000,
            color: 'success',
            position: 'top-right',
            icon: 'check_box',
            title: 'ทำรายการสำเร็จ',
            text: result.info
          })
        } else {
          this.$vs.notify({
            time: 4000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ทำรายการไม่สำเร็จ',
            text: result.info
          })
        }
      } catch (err) {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: err.message
        })
      }
    }
    // =============================================
  },
  async mounted () {
    await axios
      .get(`member/getComment/${this.$route.params.username}`)
      .then(response => (this.commentData = response.data))

    await axios
      .get(`/member/${  this.$route.params.username}`).then(response => (this.memberData = response.data))

    await axios
      .get(`member/showbank/${  this.$route.params.username}`).then(response => (this.memberDatabank = response
        .data))
    await axios
      .get(`member/showbankbay/${  this.$route.params.username}`).then(response => (this.memberDatabankbay =
          response.data))

    if (this.memberDatabank.bank_reserve === false) {
      this.memberDatabank.bank_reserve = '(บัญขีหลัก)'
    } else if (this.memberDatabank.bank_reserve === true)  {
      this.memberDatabank.bank_reserve = '(บัญขีสำรอง)'
    }

    if (this.memberDatabankbay.bank_reserve === false) {
      this.memberDatabankbay.bank_reserve = '(บัญขีหลัก)'
    } else if (this.memberDatabankbay.bank_reserve === true)  {
      this.memberDatabankbay.bank_reserve = '(บัญขีสำรอง)'
    }

    if (this.memberData.member_max_deposit >= 0 && this.memberData.member_max_deposit <= 9999) {
      this.max_deposit = 'VIP0'
    } else if (this.memberData.member_max_deposit >= 10000 && this.memberData.member_max_deposit <= 19999) {
      this.max_deposit = 'VIP1'
    } else if (this.memberData.member_max_deposit >= 20000 && this.memberData.member_max_deposit <= 29999) {
      this.max_deposit = 'VIP2'
    } else if (this.memberData.member_max_deposit >= 30000 && this.memberData.member_max_deposit <= 49999) {
      this.max_deposit = 'VIP3'
    } else {
      this.max_deposit = 'VIP4'
    }


    await axios
      .get(`member/sumtransaction/${  this.$route.params.username}`).then(response => (this
        .memberDataSumtransaction = response.data))
    this.memberDataSumtransaction[0] = this.currency(this.memberDataSumtransaction[0])
    this.memberDataSumtransaction[1] = this.currency(this.memberDataSumtransaction[1])

    // if (this.memberData.oneTimeMessage) {
    //   this.$vs.notify({
    //     time: 10000,
    //     color: 'success',
    //     position: 'top-right',
    //     icon: 'info',
    //     title: 'ข้อความจากระบบ',
    //     text: this.memberData.oneTimeMessage
    //   })
    // }
  }
}

</script>
