<template>
  <vx-card no-shadow title="แก้ไขข้อมูลยูสเซอร์">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          class="w-full mt-4"
          label="ยูสเซอร์เนม"
          v-model="memberData.member_username"
          v-validate="'required|alpha_num'"
          name="username"
          readonly
        />
        <vs-input
          class="w-full mt-4"
          label="เบอร์โทร"
          v-model="newmemberData.phone"
          v-validate="'required|numeric'"
          name="phone"
        />
        <span class="text-danger text-sm" v-show="errors.has('phone')">{{
          errors.first("phone")
        }}</span>

        <vs-input
          class="w-full mt-4"
          label="ชื่อ"
          v-model="newmemberData.name"
          v-validate="'required'"
          name="name"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first("name")
        }}</span>

        <vs-input
          class="w-full mt-4"
          label="เลขบัญชี"
          v-model="newmemberData.banknumber"
          v-validate="'required'"
          name="banknumber"
        />
        <span class="text-danger text-sm" v-show="errors.has('banknumber')">{{
          errors.first("banknumber")
        }}</span>
        <vs-input
          class="w-full mt-4"
          label="ยอดเทิร์นโอเวอร์"
          v-model="newmemberData.turnover"
          name="turnover"
        />
        <span class="text-danger text-sm" v-show="errors.has('turnover')">{{
          errors.first("turnover")
        }}</span>
        <vs-input
          class="w-full mt-4"
          label="ลิมิตถอน"
          v-model="newmemberData.limitwithdraw"
          v-validate="'required|decimal:2'"
          name="limitwithdraw"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('limitwithdraw')"
          >{{ errors.first("limitwithdraw") }}</span
        >
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          class="w-full mt-4"
          label="ไลน์"
          v-model="newmemberData.line"
          name="line"
        />
        <span class="text-danger text-sm" v-show="errors.has('line')">{{
          errors.first("line")
        }}</span>

        <vs-input
          class="w-full mt-4"
          label="รหัสผ่าน"
          v-model="newmemberData.password"
          name="password"
        />
        <span class="text-warning text-sm"
          >รหัสผ่านเข้า UFA ต้องมีอักษรกับตัวเลขผสมกัน และความยาว 8 - 15
          ตัวอักษร</span
        >
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>

        <vs-input
          class="w-full mt-4"
          label="นามสกุล"
          v-model="newmemberData.surname"
          v-validate="'required'"
          name="surname"
        />
        <span class="text-danger text-sm" v-show="errors.has('surname')">{{
          errors.first("surname")
        }}</span>

        <div class="mt-4">
          <label class="vs-input--label">ธนาคาร</label>
          <vs-select class="w-full" v-model="selectbank.value">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in options_bank"
            />
          </vs-select>
        </div>
        <div class="mt-4">
          <div v-if="newmemberData.memberlock == 1">
            <vs-input
              class="w-full mt-4"
              label="หมายเหตุ"
              v-model="newmemberData.info"
              v-validate="'required'"
              name="info"
            />
            <span class="text-danger text-sm" v-show="errors.has('info')">{{
              errors.first("info")
            }}</span>
            <span class="text-danger text-sm"
              >ยูสนี้ถูกระงับการใข้งาน กดปุ่มยืนยันเพื่อบันทึกข้อมูล</span
            >
          </div>
          <div v-else>
            <span class="text-warning text-sm mb-2"
              >เลื่อนเปิดเพื่อระงับการใข้งาน กดปุ่มยืนยันเพื่อบันทึกข้อมูล</span
            >
          </div>
          <vs-switch v-model="newmemberData.memberlock">
            <span slot="0">เปิด</span>
            <span slot="1">ปิด</span>
          </vs-switch>
        </div>
      </div>
    </div>
    <div class="mt-6 flex flex-wrap items-center justify-end">
      <vs-button color="success" class="ml-4 mt-2" @click="Save"
        >ยืนยัน</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import axios from '../../../axios'
import vSelect from 'vue-select'

import { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'กรุณากรอกข้อมูล'
    },
    banknumber: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'ต้องเป็นตัวเลขเท่านั้น'
    },
    phone: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'ต้องเป็นตัวเลขเท่านั้น'
    },
    surname: {
      required: 'กรุณากรอกข้อมูล'
    },
    turnover: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    limitwithdraw: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      turnover: 0,
      bank_see: '',
      sum_deposit: 0,
      selectbank: {
        text: '',
        value: ''
      },
      newmemberData: {
        phone: '',
        name: '',
        banknumber: '',
        turnover: '',
        line: '',
        password: '',
        surname: '',
        bankcode: '',
        banktype: '',
        memberlock: '',
        member_locked_info: '',
        limitwithdraw: ''
      },
      memberData: {},
      errorlog: [],
      info_log: '',
      options_bank: [
        {
          text: 'ไทยพาณิชย์',
          value: '000'
        },
        {
          text: 'กรุงเทพ',
          value: '002'
        },
        {
          text: 'กสิกรไทย',
          value: '004'
        },
        {
          text: 'กรุงไทย',
          value: '006'
        },
        {
          text: 'ธกส',
          value: '034'
        },
        {
          text: 'ทหารไทยธนชาต',
          value: '011'
        },
        {
          text: 'ไอซีบีซี',
          value: '070'
        },
        {
          text: 'ไทยเครดิต',
          value: '071'
        },
        {
          text: 'ซิตี้แบงก์',
          value: '017'
        },
        {
          text: 'ซูมิโตโม มิตซุย',
          value: '018'
        },
        {
          text: 'สแตนดาร์ดชาร์เต',
          value: '020'
        },
        {
          text: 'ซีไอเอ็มบี',
          value: '022'
        },
        {
          text: 'ยูโอบี',
          value: '024'
        },
        {
          text: 'กรุงศรีฯ',
          value: '025'
        },
        {
          text: 'ออมสิน',
          value: '030'
        },
        {
          text: 'เอชเอสบีซี',
          value: '031'
        },
        {
          text: 'มิซูโฮ',
          value: '039'
        },
        {
          text: 'ธอส.',
          value: '033'
        },
        {
          text: 'แลนด์แอนด์เฮ้าส',
          value: '073'
        },
        {
          text: 'ทิสโก้',
          value: '067'
        },
        {
          text: 'เกียรตินาคิน',
          value: '069'
        },
        {
          text: 'อิสลาม',
          value: '066'
        }
      ]
    }
  },
  methods: {
    async Save () {
      for (let i = 0; i < this.options_bank.length; i++) {
        if (this.selectbank.value === this.options_bank[i].value) {
          this.selectbank.text = this.options_bank[i].text
        }
      }
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await axios
            .post('member/editinfonew', {
              username: this.memberData.member_username,
              phone: this.newmemberData.phone,
              name: this.newmemberData.name,
              bank: this.selectbank.value,
              bankname: this.selectbank.text,
              memberlocked: this.newmemberData.memberlock,
              line: this.newmemberData.line,
              password: this.newmemberData.password,
              surname: this.newmemberData.surname,
              info: this.newmemberData.info,
              banknumber: this.newmemberData.banknumber,
              limitwithdraw: this.newmemberData.limitwithdraw,
              turnover: this.newmemberData.turnover
            })
            .then((response) => (this.errorlog = response.data))
          if (this.errorlog.status === false) {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'ทำรายการไม่สำเร็จ',
              text: this.errorlog.info
            })
          } else {

            this.$vs.notify({
              time: 8000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: 'ทำรายการสำเร็จ',
              text: this.errorlog.info
            })
          }
        } else {
          this.$vs.notify({
            time: 3000,
            color: 'warning',
            position: 'top-right',
            icon: 'error',
            title: 'ทำรายการไม่สำเร็จ',
            text: 'กรุณากรอกข้อมูลให้ครบ'
          })
        }
      })
    }
  },
  async mounted () {
    if (!this.$store.state.AppActiveUser.permissions.user.action) location.href = '/error-404'

    await axios
      .get(`/member/${this.$route.params.username}`)
      .then((response) => (this.memberData = response.data))
    this.selectbank.text = this.memberData.member_bank_type
    this.selectbank.value = this.memberData.member_bank_code
    this.newmemberData.username = this.memberData.member_username
    this.newmemberData.phone = this.memberData.member_phone
    this.newmemberData.name = this.memberData.member_name
    this.newmemberData.banknumber = this.memberData.member_bank_number
    this.newmemberData.turnover = this.memberData.member_turnover
    this.newmemberData.line = this.memberData.member_line
    this.newmemberData.password = this.memberData.member_password
    this.newmemberData.surname = this.memberData.member_surname
    this.newmemberData.bankcode = this.memberData.member_bank_code
    this.newmemberData.memberlock = this.memberData.member_locked
    this.newmemberData.info = this.memberData.member_locked_info
    this.newmemberData.banktype = this.memberData.member_bank_type
    this.newmemberData.limitwithdraw = this.memberData.member_limitwithdraw
  }
}
</script>
